import { IComponentMapper } from '@wix/editor-elements-types';

const MAPPER_EXTENSION = '.mapper.ts';

const componentEntriesContext = require.context(
  './components',
  true,
  /mapper\.ts$/,
);

function getComponentName(path: string) {
  return path
    .split('/')
    .pop()!
    .replace(MAPPER_EXTENSION, '');
}

const componentMapperEntries = componentEntriesContext
  .keys()
  .reduce<Record<string, IComponentMapper>>((acc, filePath) => {
    const componentName = getComponentName(filePath);
    acc[componentName] = componentEntriesContext(filePath).default;
    return acc;
  }, {});

export default componentMapperEntries;
