// TODO - I think thunderbolt-becky-types should not be used directly, and should be used via editor-elements-types
import { Language } from '@wix/thunderbolt-becky-types';

export const WIX_RECAPTCHA_LANGUAGE_MAP: Record<Language, string> = {
  de: 'de',
  en: 'en',
  es: 'es',
  fr: 'fr',
  it: 'it',
  ja: 'ja',
  ko: 'ko',
  pl: 'pl',
  ru: 'ru',
  nl: 'nl',
  tr: 'tr',
  sv: 'sv',
  pt: 'pt',
  no: 'no',
  da: 'da',
  hi: 'hi',
  zh: 'zh',
  cs: 'cs',
  th: 'th',
  he: 'iw',
} as const;
