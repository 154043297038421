import {
  withCompInfo,
  createComponentMapper,
} from '@wix/editor-elements-integrations';
import {
  FacebookShareDefinition,
  FacebookSharePlatformProps,
} from '../FacebookShare.types';

const props = withCompInfo<FacebookSharePlatformProps, FacebookShareDefinition>(
  ['compData'],
  ({ compData }) => {
    return { label: compData.label, socialUrl: '' };
  },
);

export default createComponentMapper({ props });
