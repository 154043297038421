import {
  withCompInfo,
  createComponentMapper,
} from '@wix/editor-elements-integrations';
import { WIX_RECAPTCHA_LANGUAGE_MAP } from '../constants';
import { CaptchaDefinition, CaptchaPlatformProps } from '../Captcha.types';

const props = withCompInfo<CaptchaPlatformProps, CaptchaDefinition>(
  ['language'],
  ({ language }) => {
    return {
      language: WIX_RECAPTCHA_LANGUAGE_MAP[language],
    };
  },
);

export default createComponentMapper({
  props,
});
